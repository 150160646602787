import { PortalServico } from '@/utils/';

/**
 * @type {DashServico}
 */
export default class DashServico extends PortalServico {

    mviListarDashAcomp(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarDashAcomp", params);
    }

    mviListarChamada(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarChamada", params);
    }

    mviListarDashGrafArq(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarDashGrafArq", params);
    }
    
    mviListarDashHeteroid(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarDashHeteroid", params);
    }
    
    mviListarDashCancelamento(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarDashCancelamento", params);
    }
    
    mviListarDashGrafUnid(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarDashGrafUnid", params);
    }

    mviListarDashBancaHet(params) {
        return this.post("/uspproc/listar/jupiterweb/MVIlistarDashBancaHet", params);
    }
}
