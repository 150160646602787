<template>
    <section style="min-height: 1000px">
        <div class="container-fluid">
            <uspCartao titulo="Dashboard da matrícula dos ingressantes">
                <template slot="corpo">
                    <form class="form-inline">
                        <select class="form-control" v-model="filtro.anomtrviring">
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                        </select>
                        <select class="form-control ml-sm-3" v-model="filtro.staoriing">
                            <option value="F">Fuvest</option>
                            <option value="E">ENEM USP</option>
                        </select>
                        <select class="form-control ml-sm-3" v-model="filtro.numlst">
                            <option v-for="item in chamadas" :key="item.numlst" :value="item.numlst">{{ item.titlst }}</option>
                        </select>
                    </form>
                </template>
            </uspCartao>
            <div class="row">
                <div class="col-sm-8">
                    <div class="row">
                        <div class="col-sm-6">
                            <uspCartao titulo="Acompanhamento">
                                <template slot="corpo">
                                    <div>
                                        <p>Atualizado em: {{ acomp.atualizado }}</p>
                                        <select class="form-control" v-model="acompTipo">
                                            <option value="T">Texto</option>
                                            <option value="G">Gráfico</option>
                                        </select>
                                        <div v-show="acompTipo == 'G'" id="acomp" class="col-12 mt-3"></div>
                                        <div v-show="acompTipo == 'T'" class="form-row table-responsive mt-3">
                                            <table class="table" style="margin-bottom: 0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 75%">Grupo</th>
                                                        <th scope="col" class="text-right" style="width: 25%">Contagem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Convocados</td>
                                                        <td class="text-right">{{ acomp.convocados }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Entraram no sistema</td>
                                                        <td class="text-right">{{ acomp.entraram }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Enviaram documentos para USP</td>
                                                        <td class="text-right">{{ acomp.enviaram }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Aguardando análise da USP</td>
                                                        <td class="text-right">{{ acomp.aguardando }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Matrículas aceitas</td>
                                                        <td class="text-right">{{ acomp.aceitas }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Desistentes</td>
                                                        <td class="text-right">{{ acomp.desistentes }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>USP devolveu para ingressante com pendência</td>
                                                        <td class="text-right">{{ acomp.pendentes }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Matrículas rejeitadas</td>
                                                        <td class="text-right">{{ acomp.rejeitadas }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </template>
                            </uspCartao>
                        </div>
                        <div class="col-sm-6">
                            <uspCartao titulo="Heteroidentificação">
                                <template slot="corpo">
                                    <div>
                                        <p>Atualizado em: {{ heteroid.atualizado }}</p>
                                        <select class="form-control" v-model="heteroidTipo">
                                            <option value="T">Texto</option>
                                            <option value="G">Gráfico</option>
                                        </select>
                                        <div v-show="heteroidTipo == 'G'" id="heteroid" class="col-12 mt-3"></div>
                                        <div v-show="heteroidTipo == 'T'" class="form-row table-responsive mt-3">
                                            <table class="table" style="margin-bottom: 0">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 75%">Grupo</th>
                                                        <th scope="col" class="text-right" style="width: 25%">Contagem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Total de indígenas</td>
                                                        <td class="text-right">{{ heteroid.indigenas }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total de PPs</td>
                                                        <td class="text-right">{{ heteroid.pps }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Documentos acadêmicos aceitos</td>
                                                        <td class="text-right">{{ heteroid.aceitos }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Foram para segunda banca</td>
                                                        <td class="text-right">{{ heteroid.banca_2 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Foram para presencial</td>
                                                        <td class="text-right">{{ heteroid.presencial }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Foram para recurso</td>
                                                        <td class="text-right">{{ heteroid.recurso }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Em análise</td>
                                                        <td class="font-weight-bold text-right">
                                                            {{ heteroid.analise_total }}
                                                            <i v-show="!heteroidAnalise" @click="alternarHeteroidAnalise()" class="fas fa-chevron-down ml-2"></i>
                                                            <i v-show="heteroidAnalise" @click="alternarHeteroidAnalise()" class="fas fa-chevron-up ml-2"></i>
                                                        </td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5">na primeira banca</td>
                                                        <td class="text-right">{{ heteroid.analise_banca_1 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com nenhum parecer</span></td>
                                                        <td class="text-right">{{ heteroid.banca_1_analise_0 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com um parecer</span></td>
                                                        <td class="text-right">{{ heteroid.banca_1_analise_1 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 2 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_1_analise_2 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 3 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_1_analise_3 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 4 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_1_analise_4 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5">na segunda banca</td>
                                                        <td class="text-right">{{ heteroid.analise_banca_2 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com nenhum parecer</span></td>
                                                        <td class="text-right">{{ heteroid.banca_2_analise_0 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com um parecer</span></td>
                                                        <td class="text-right">{{ heteroid.banca_2_analise_1 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 2 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_2_analise_2 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 3 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_2_analise_3 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 4 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_2_analise_4 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5">no presencial</td>
                                                        <td class="text-right">{{ heteroid.analise_presencial }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com nenhum parecer</span></td>
                                                        <td class="text-right">{{ heteroid.banca_p_analise_0 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com um parecer</span></td>
                                                        <td class="text-right">{{ heteroid.banca_p_analise_1 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 2 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_p_analise_2 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 3 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_p_analise_3 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5"><span class="ml-5">com 4 pareceres</span></td>
                                                        <td class="text-right">{{ heteroid.banca_p_analise_4 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAnalise">
                                                        <td class="pl-5">no recurso</td>
                                                        <td class="text-right">{{ heteroid.analise_recurso }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Aptos</td>
                                                        <td class="font-weight-bold text-right">
                                                            {{ heteroid.aptos_total }}
                                                            <i v-show="!heteroidAptos" @click="alternarHeteroidAptos()" class="fas fa-chevron-down ml-2"></i>
                                                            <i v-show="heteroidAptos" @click="alternarHeteroidAptos()" class="fas fa-chevron-up ml-2"></i>
                                                        </td>
                                                    </tr>
                                                    <tr v-show="heteroidAptos">
                                                        <td class="pl-5">na primeira banca</td>
                                                        <td class="text-right">{{ heteroid.aptos_banca_1 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAptos">
                                                        <td class="pl-5">na segunda banca</td>
                                                        <td class="text-right">{{ heteroid.aptos_banca_2 }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAptos">
                                                        <td class="pl-5">no presencial</td>
                                                        <td class="text-right">{{ heteroid.aptos_presencial }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidAptos">
                                                        <td class="pl-5">no recurso</td>
                                                        <td class="text-right">{{ heteroid.aptos_recurso }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-weight-bold">Inaptos</td>
                                                        <td class="font-weight-bold text-right">
                                                            {{ heteroid.inaptos_total }}
                                                            <i v-show="!heteroidInaptos" @click="alternarHeteroidInaptos()" class="fas fa-chevron-down ml-2"></i>
                                                            <i v-show="heteroidInaptos" @click="alternarHeteroidInaptos()" class="fas fa-chevron-up ml-2"></i>
                                                        </td>
                                                    </tr>
                                                    <tr v-show="heteroidInaptos">
                                                        <td class="pl-5">no presencial</td>
                                                        <td class="text-right">{{ heteroid.inaptos_presencial }}</td>
                                                    </tr>
                                                    <tr v-show="heteroidInaptos">
                                                        <td class="pl-5">no recurso</td>
                                                        <td class="text-right">{{ heteroid.inaptos_recurso }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </template>
                            </uspCartao>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <uspCartao titulo="Documentos, envios e análises por hora">
                                <template slot="corpo">
                                    <div id="grafArq" class="col-12 mt-3 p-0"></div>
                                </template>
                            </uspCartao>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="row">
                        <div class="col">
                            <uspCartao titulo="Visão por unidade">
                                <template slot="corpo">
                                    <div>
                                        <div id="grafUnid" style="min-height: 1000px;" class="col-12 mt-3 p-0"></div>
                                    </div>
                                </template>
                            </uspCartao>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <uspCartao titulo="Cancelamento">
                        <template slot="corpo">
                            <div>
                                <p>Atualizado em: {{ cancelamento.atualizado }}</p>
                                <select class="form-control" v-model="cancelamentoTipo">
                                    <option value="T">Texto</option>
                                    <option value="G">Gráfico</option>
                                </select>
                                <div v-show="cancelamentoTipo == 'G'" id="cancelamento" class="col-12 mt-3"></div>
                                <div v-show="cancelamentoTipo == 'T'" class="form-row table-responsive mt-3">
                                    <table class="table" style="margin-bottom: 0">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 75%">Grupo</th>
                                                <th scope="col" class="text-right" style="width: 25%">Contagem</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Solicitaram</td>
                                                <td class="text-right">{{ cancelamento.solicitaram }}</td>
                                            </tr>
                                            <tr>
                                                <td>Aguardando análise</td>
                                                <td class="text-right">{{ cancelamento.analise }}</td>
                                            </tr>
                                            <tr>
                                                <td>Efetuados</td>
                                                <td class="text-right">{{ cancelamento.efetuados }}</td>
                                            </tr>
                                            <tr>
                                                <td>Indeferidos</td>
                                                <td class="text-right">{{ cancelamento.indeferidos }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                    </uspCartao>
                </div>
                <div class="col-sm-4">
                    <uspCartao titulo="Banca de heteroidentificação">
                        <template slot="corpo">
                            <div>
                                <div class="form-row table-responsive mt-3">
                                    <table class="table" style="margin-bottom: 0">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 50%">Membro</th>
                                                <th scope="col" style="width: 25%">Banca</th>
                                                <th scope="col" class="text-right" style="width: 25%">Análises</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in bancaHet">
                                                <td>{{ item.nompes }}</td>
                                                <td>{{ item.codbanhti == 1 ? 'Titular' : 'Suplente' }}</td>
                                                <td class="text-right">
                                                    {{ item.analises }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>
                    </uspCartao>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import DashServico from "../dominio/DashServico";
import Plotly from 'plotly.js-dist';
const api = DashServico.build({});
export default {
    name: "MatrVirtualIngress",
    data() {
        return {
            acomp: {},
            chamadas: [],
            filtro: {
                anomtrviring: 2023,
                staoriing: "F",
                numlst: "1"
            },
            heteroid: {},
            bancaHet: [],
            heteroidAptos: false,
            heteroidAnalise: false,
            heteroidInaptos: false,
            acompTipo: "T",
            heteroidTipo: "T",
            cores: [
                "#1093AA", //azul/verde
                "#FCB421", //amarelo
                "#D75A4A", //vermelho
                "#B8B8B8", //cinza
                "#D78878" //marrom
            ],
            cancelamentoTipo: "T",
            cancelamento: {}
        };
    },
    watch: {
        'filtro.anomtrviring': function() {
            var self = this;
            self.listarTudo();
        },
        'filtro.staoriing': function() {
            var self = this;
            self.listarTudo();
        },
        'filtro.numlst': function() {
            var self = this;
            self.listarTudo();
        },
        'acompTipo': function() {
            var self = this;
            if (self.acompTipo == 'G') {
                setTimeout(function() {
                    Plotly.relayout("acomp", {
                        width: document.querySelector("#acomp").clientWidth - 40,
                        height: document.querySelector("#acomp").clientHeight,
                    });
                }, 500);
            }
        },
        'heteroidTipo': function() {
            var self = this;
            if (self.heteroidTipo == 'G') {
                setTimeout(function() {
                    Plotly.relayout("heteroid", {
                        width: document.querySelector("#heteroid").clientWidth - 40,
                        height: document.querySelector("#heteroid").clientHeight,
                    });
                }, 500);
            }
        },
        'cancelamentoTipo': function() {
            var self = this;
            if (self.cancelamentoTipo == 'G') {
                setTimeout(function() {
                    Plotly.relayout("cancelamento", {
                        width: document.querySelector("#cancelamento").clientWidth - 40,
                        height: document.querySelector("#cancelamento").clientHeight,
                    });
                }, 500);
            }
        }
    },
    methods: {
        alternarHeteroidAptos() {
            var self = this;
            self.heteroidAptos = !self.heteroidAptos;
        },
        alternarHeteroidInaptos() {
            var self = this;
            self.heteroidInaptos = !self.heteroidInaptos;
        },
        alternarHeteroidAnalise() {
            var self = this;
            self.heteroidAnalise = !self.heteroidAnalise;
        },
        listarTudo() {
            var self = this;
            self.listarAcomp();
            self.listarGrafArq();
            self.listarHeteroid();
            self.listarBancaHet();
            self.listarCancelamento();
            self.listarGrafUnid();
        },
        listarChamada() {
            var self = this;
            self.chamadas = [];
            self.$forceUpdate();
            api.mviListarChamada(self.filtro).then(lista => {
                self.chamadas = lista;
                self.listarTudo();
            });
        },
        listarGrafArq() {
            var self = this;
            api.mviListarDashGrafArq(self.filtro).then(lista => {
                Plotly.newPlot("grafArq", [{
                    type: "scatter",
                    name: "Upload de documentos",
                    x: lista.filter(e => e.tipo == 1).map(e => e.x),
                    y: lista.filter(e => e.tipo == 1).map(e => e.y)
                }, {
                    type: "scatter",
                    name: "Matrículas enviadas para análise",
                    x: lista.filter(e => e.tipo == 2).map(e => e.x),
                    y: lista.filter(e => e.tipo == 2).map(e => e.y)
                }, {
                    type: "scatter",
                    name: "Matrículas analisadas",
                    x: lista.filter(e => e.tipo == 3).map(e => e.x),
                    y: lista.filter(e => e.tipo == 3).map(e => e.y)
                }], {
                    colorway: self.cores,
                    legend: {"orientation": "h"},
                    margin: {
                        t: 0,
                        b: 0
                    }
                });
            });
        },
        listarGrafUnid() {
            var self = this;
            api.mviListarDashGrafUnid(self.filtro).then(lista => {
                Plotly.newPlot("grafUnid", [{
                    type: "bar",
                    name: "Não enviado ou devolvido",
                    orientation: "h",
                    y: lista.filter(e => e.conjunto == 1).map(e => e.x),
                    x: lista.filter(e => e.conjunto == 1).map(e => e.y),
                }, {
                    type: "bar",
                    name: "Aguardando análise",
                    orientation: "h",
                    y: lista.filter(e => e.conjunto == 2).map(e => e.x),
                    x: lista.filter(e => e.conjunto == 2).map(e => e.y),
                }, {
                    type: "bar",
                    name: "Concluído",
                    orientation: "h",
                    y: lista.filter(e => e.conjunto == 3).map(e => e.x),
                    x: lista.filter(e => e.conjunto == 3).map(e => e.y),
                }], {
                    margin: {
                        t: 0,
                        b: 0
                    },
                    colorway: self.cores,
                    barmode: "stack",
                    legend: {"orientation": "h"}
                });
            });
        },
        listarAcomp() {
            var self = this;
            self.acomp = {};
            self.$forceUpdate();
            api.mviListarDashAcomp(self.filtro).then(lista => {
                self.acomp = lista[0];
                Plotly.newPlot("acomp", [{
                    type: "pie",
                    values: [self.acomp.convocados - self.acomp.entraram, self.acomp.entraram - self.acomp.enviaram, self.acomp.aguardando, self.acomp.aceitas, self.acomp.pendentes, self.acomp.desistentes, self.acomp.rejeitadas],
                    labels: ["Não entraram", "Não enviaram", "Aguardando análise", "Matrículas aceitas", "Pendentes", "Desistentes", "Rejeitadas"],
                    textinfo: "label+percent",
                    insidetextorientation: "auto",
                    automargin: true
                }], {
                    margin: {
                        l: 0,
                        t: 0,
                        b: 0,
                        r: 0
                    }, colorway: self.cores,
                    showlegend: false
                });
            });
        },
        listarCancelamento() {
            var self = this;
            self.cancelamento = {};
            self.$forceUpdate();
            api.mviListarDashCancelamento(self.filtro).then(lista => {
                self.cancelamento = lista[0];
                Plotly.newPlot("cancelamento", [{
                    type: "pie",
                    labels: ["Aguardando análise", "Efetuados", "Indeferidos"],
                    values: [self.cancelamento.analise, self.cancelamento.efetuados, self.cancelamento.indeferidos],
                    textinfo: "label+percent",
                    insidetextorientation: "auto",
                    automargin: true
                }], {
                    margin: {
                        l: 0,
                        t: 0,
                        b: 0,
                        r: 0
                    }, colorway: self.cores,
                    showlegend: false
                });
            });
        },
        listarHeteroid() {
            var self = this;
            self.heteroid = {};
            self.$forceUpdate();
            api.mviListarDashHeteroid(self.filtro).then(lista => {
                self.heteroid = lista[0];
                Plotly.newPlot("heteroid", [{
                    type: "pie",
                    values: [self.heteroid.aptos_total, self.heteroid.analise_total, self.heteroid.inaptos_total],
                    labels: ["Aptos", "Em análise", "Inaptos"],
                    textinfo: "label+percent",
                    insidetextorientation: "auto",
                    automargin: true
                }], {
                    margin: {
                        l: 0,
                        t: 0,
                        b: 0,
                        r: 0
                    }, colorway: self.cores,
                    showlegend: false
                });
            });
        },
        listarBancaHet() {
            var self = this;
            self.bancaHet = [];
            self.$forceUpdate();
            api.mviListarDashBancaHet(self.filtro).then(lista => {
                self.bancaHet = lista;
            });
        }
    },
    mounted() {
        var self = this;
        self.listarChamada();
    },
};
</script>
<style scoped>
.card-header {
    padding-bottom: 0rem !important;
}
</style>
